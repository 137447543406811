import React from 'react';
import styled from 'styled-components';
import { styles, components } from 'herald-fe-shared';

import { Container } from '~/layout/Layout';
import Layout from '~/layout/LayoutMarketing';
import Illustration from '~/illustrations/IllustrationFlying';

const Styled = styled.article`
  display: flex;
  flex-flow: column;
  .container {
    margin: 5rem auto;
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 4rem;
    min-height: 60vh;
  }
  .container__right {
    display: flex;
    align-items: center;
  }
  h2,
  p {
    margin-bottom: 1rem;
  }
  iframe {
    border-radius: 5px;
    box-shadow: ${styles.BOX_SHADOW};
    height: 100%;
  }
  @media screen and (max-width: 800px) {
    .container {
      grid-template-columns: 1fr;
    }
    .container__left {
      max-width: 24rem;
      margin: auto;
    }
    .container__right__message {
      max-width: 40rem;
      margin: 0 auto;
    }
    iframe {
      min-height: 30rem;
    }
  }
  @media screen and (max-width: 500px) {
    .container__left {
      display: none;
    }
  }
`;

const RequestAccess = () => {
  return (
    <Layout title="Request Acces Sign Up">
      <Styled>
        <Container className="container">
          <div style={{ width: '40rem', maxWidth: 'calc(100vw - 8rem)' }}>
            <Illustration
              width="30vw"
              height="30vw"
              style={{ marginBottom: 50 }}
            />
            <h2>It&#39;s Time to Say Goodbye</h2>
            <p>
              Despite our best efforts, the Herald team hasn't been able to make
              this product a viable business for ourselves, leaving us no choice
              but to shut it down.
            </p>
            <p>
              For us, the worst part is letting our customers down, especially
              the ones that have been as instrumental to our progress thus far
              as you have. I’m sure many have questions, and we’ve tried to
              address as many of them as we could below, but the short answer
              is: we are happy to help you transition off of the Herald platform
              however we can.
            </p>
            <p>
              Lastly, we wanted to thank you for believing in our product. While
              we’re sorry it has to end this way, we had a ton of fun listening
              to and building for customers like you. We wish you all the best
              with the business and please don’t hesitate to reach out if you
              have any questions.
            </p>

            <p>
              <b>Will I get all my data from Herald?</b>
              <br />
              Yes. We plan to make it available directly through the UI, a large
              data dump in CSVs. We expect to live with this by September 30, 2021.
            </p>

            <p>
              <b>How long do I have to retrieve my data?</b>
              <br />
              We plan to keep Herald up and running in its current state until
              October 31, 2021. You can download all your data any time before
              then.
            </p>

            <p>
              <b>Do I have to keep paying for Herald?</b>
              <br />
              No, we are pausing any further billing of subscriptions
              immediately. You can continue to use Herald in its current state
              free of charge until October 31.
            </p>

            <p>
              <b>What happens after October 31?</b>
              <br />
              We will begin the process of decommissioning our cloud resources
              and winding the business down.
            </p>

            <p>
              <b>Will you be keeping any of my data?</b>
              <br />
              No, after October 31, we will be deleting all our databases and
              destroying any data you’ve added to the Herald platform.
            </p>

            <p>
              Thanks,
              <br />
              Jay and Neel
            </p>
          </div>
        </Container>
      </Styled>
    </Layout>
  );
};

export default RequestAccess;
