import * as React from "react";

const SvgIllustrationFlying = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 323 327" fill="none" {...props}>
    <path
      d="M224.585 173.998l-3.76-10.207h8.231l2.871 7.88-7.342 2.327z"
      fill="#F7B0AB"
    />
    <path
      d="M321.022 285.918l-6.533-15.446v-5.779H322l-.978 21.225z"
      fill="#000"
      stroke="#000"
      strokeWidth={0.912}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M251.748 255.798c4.231 9.363 10.699 19.725 19.522 23.218 15.997 6.332 43.219-8.544 43.219-8.544v-5.779h-33.671l-2.513-30.655-22.436 3.106.576-2.604-28.897 4.523s-1.72 25.715 2.764 41.208c4.432 15.309 19.348 35.932 19.348 35.932h8.795l-11.559-38.444 4.852-21.961z"
      fill="#5D5AFF"
    />
    <path
      d="M277.971 269.654c-17.39-6.884-25.63-40.455-25.63-40.455l25.63 1.907 2.821 34.104H321.19s-27.222 10.775-43.219 4.444z"
      fill="#000"
      fillOpacity={0.08}
    />
    <path
      d="M244.462 282.874c-4.484-15.493 11.103-43.811 11.103-43.811l1.923-1.136-6.11 19.609-4.547 20.746 11.936 38.793-1.233 1.613s-8.641-20.505-13.072-35.814z"
      fill="#000"
      fillOpacity={0.16}
    />
    <path
      d="M235.338 326.003l14.323-10.302h8.948l2.328 9.254-25.599 1.048z"
      fill="#000"
      stroke="#000"
      strokeWidth={0.912}
    />
    <path
      d="M211.507 192.176c-3.605-1.776-24.228-12.158-35.217-22.465-8.871-8.319-16.141-19.913-19.705-26.121-.666-1.159.631-2.328 1.744-1.586l26.999 17.999c.028.019.055.035.083.051.843.47 14.118 7.816 23.596 9.657 12.828 2.493 32.846-2.353 35.37-2.987.177-.045.336-.056.516-.025 3.085.535 33.801 6.12 48.456 18.76 8.629 7.442 16.594 21.56 20.398 28.925.624 1.208-.767 2.339-1.843 1.509l-24.671-19.036a1.324 1.324 0 00-.33-.185l-30.873-11.82c-1.146-.439-2.207.809-1.588 1.869l29.466 50.53c.116.199.183.427.16.656-.822 8.485-31.334 12.904-58.996 5.024a1.289 1.289 0 01-.898-.936l-11.977-48.956a1.303 1.303 0 00-.69-.863z"
      fill="#FA1567"
    />
    <path
      d="M207.932 185.996c-16.486-7.589-20.354-8.529-21.242-8.484-.233.011-.143.233.055.356 10.072 6.311 16.157 10.73 24.787 14.197.392.158.688.493.789.904l9.897 40.156c.363 1.472 2.218 1.242 2.15-.272-.652-14.517-3.62-40.957-16.436-46.857z"
      fill="#000"
      fillOpacity={0.1}
    />
    <path
      d="M282.071 190.893c-14.86-6.84-23.583-6.828-27.807-6.022-1.166.223-1.048 1.435.117 1.668 6.947 1.385 23.319 5.069 33.516 10.771 3.321 1.857 9.829 7.039 14.811 11.151 1.152.951 2.276-.117 1.274-1.225-4.531-5.013-11.888-11.729-21.911-16.343z"
      fill="#1B1B1B"
      fillOpacity={0.28}
    />
    <path
      d="M144.899 129.768c1.238 6.062 4.854 9.757 11.997 13.431l1.433-.896v-5.372s-.363-10.902-2.865-8.595c-1.415 1.304-1.612 4.656-1.612 4.656s-10.192-9.286-8.953-3.224z"
      fill="#FFC7C3"
    />
    <path
      d="M219.571 166.836c-8.215-3.392-4.944-17.985 0-22.563 5.28-4.889 5.707-3.13 11.235 1.644 5.52 4.767 7.89 13.626 1.837 17.696-5.14 3.456-8.267 5.207-13.072 3.223z"
      fill="#FFC7C4"
    />
    <path
      d="M216.885 148.033c-1.328-4.284-4.211-8.018 0-9.491 12.186-4.26 17.66 7.514 27.926 11.066 12.954 4.481 33.483-3.224 42.978 6.662 5.656 5.89.275 12.037 0 20.198-.585 17.361-27.795 19.899-42.978 11.461-13.219-7.347-17.766-22.815-15.4-29.726 4.477-13.072-10.802-4.61-12.526-10.17z"
      fill="#000"
      stroke="#000"
      strokeWidth={0.65}
    />
    <path
      d="M313.226 225.392l1.074-7.342.537-1.611c6.751 3.469 6.613 4.721 5.917 11.033l-.007.069-4.656-4.656-2.865 2.507z"
      fill="#FFC7C3"
    />
    <path
      d="M95.488 78.87l11.887 3.98.778 2.712-4.785 8.53-1.075-4.836 4.075-5.266-4.758 3.654-6.122-8.774zM114.112 73.677l-6.244 9.03.778 2.713 11.196 3.836-2.507-5.014-8.043-1.224 6.969-.31-2.149-9.031zM2.045 8.072c3.396-4.234 13.397 3.698 13.397 3.698l.999 3.483c.39 1.36-.999 17.447-8.403 13.718-2.7-1.36-1.669-2.02-1.669-3.4 0-2.397 1.349-4.44 2.63-5.838-.886.381-2.055.565-3.549.214C.754 18.84-.972 11.836 2.045 8.072z"
      fill="#F7E9E3"
    />
    <path
      d="M4.678 12.93c1.17-4.017 10.386-4.779 10.386 3.674 0 8.452-3.76 8.862-4.477 10.473-.716 1.612-.597-2.217-.716-3.76-.218-2.816 2.149-6.713 2.149-6.713s-2.793 2.32-5.193.983c-2.224-1.24-2.723-2.687-2.15-4.656z"
      fill="#fff"
    />
    <path
      d="M25.686.275c-5.152-1.811-9.588 11.313-9.588 11.313l1.08 3.865s8.968 11.498 14.027 6.899c2.237-2.034.92-4.6-1.15-5.962-2.344-1.544-3.817-2.291-4.739-2.635 1.024-.096 2.472-.575 4.05-2.167 3.396-3.425.87-9.712-3.68-11.313z"
      fill="#F7E9E3"
    />
    <path
      d="M24.149 6.471c-3.703-1.948-10.653 4.151-4.573 10.023 6.08 5.871 8.987 3.45 10.644 4.055 1.657.604-1.18-1.97-2.207-3.128-1.874-2.112-6.322-3.117-6.322-3.117s3.61-.397 4.315-3.053c.653-2.46-.041-3.824-1.857-4.78z"
      fill="#fff"
    />
    <path
      d="M15.203 12.175v-1.612l1.253.896 2.507 9.49-3.76-8.774z"
      fill="#fff"
    />
    <path
      d="M44.278 14.937c1.303-1.625 5.141 1.419 5.141 1.419l.383 1.336c.15.522-.383 6.695-3.224 5.264-1.036-.521-.64-.775-.64-1.304 0-.92.517-1.704 1.008-2.24-.34.146-.788.216-1.361.082-1.802-.425-2.465-3.113-1.307-4.557z"
      fill="#F7E9E3"
    />
    <path
      d="M45.288 16.801c.45-1.542 3.986-1.834 3.986 1.41 0 3.243-1.443 3.4-1.718 4.019-.275.618-.23-.851-.275-1.443-.083-1.08.825-2.576.825-2.576s-1.072.89-1.993.377c-.853-.476-1.045-1.031-.825-1.787z"
      fill="#fff"
    />
    <path
      d="M53.35 11.945c-1.977-.696-3.68 4.341-3.68 4.341l.415 1.483s3.442 4.412 5.383 2.647c.858-.78.353-1.765-.441-2.288-.9-.592-1.465-.879-1.819-1.01.393-.037.949-.221 1.554-.832 1.303-1.315.334-3.727-1.412-4.341z"
      fill="#F7E9E3"
    />
    <path
      d="M52.76 14.322c-1.42-.747-4.087 1.593-1.754 3.846 2.333 2.254 3.448 1.325 4.084 1.557.636.231-.453-.756-.847-1.2-.72-.811-2.426-1.197-2.426-1.197s1.385-.152 1.656-1.171c.25-.945-.016-1.468-.713-1.835z"
      fill="#fff"
    />
    <path
      d="M49.327 16.511v-.618l.481.343.962 3.642-1.443-3.367z"
      fill="#fff"
    />
    <path
      d="M31.385 41.081c1.303-1.624 5.14 1.42 5.14 1.42l.384 1.336c.15.522-.383 6.695-3.225 5.264-1.036-.522-.64-.775-.64-1.304 0-.92.518-1.704 1.01-2.241-.34.147-.79.217-1.363.082-1.802-.424-2.464-3.112-1.306-4.556z"
      fill="#F7E9E3"
    />
    <path
      d="M32.395 42.946c.45-1.542 3.986-1.834 3.986 1.41 0 3.243-1.444 3.4-1.718 4.018-.275.619-.23-.85-.275-1.443-.084-1.08.824-2.576.824-2.576s-1.071.89-1.992.378c-.854-.476-1.045-1.031-.825-1.787z"
      fill="#fff"
    />
    <path
      d="M40.457 38.09c-1.977-.696-3.68 4.34-3.68 4.34l.415 1.484s3.441 4.412 5.383 2.647c.858-.78.353-1.765-.442-2.288-.899-.592-1.464-.879-1.818-1.01.393-.038.948-.222 1.554-.832 1.303-1.315.334-3.727-1.412-4.342z"
      fill="#F7E9E3"
    />
    <path
      d="M39.867 40.467c-1.421-.748-4.088 1.593-1.755 3.846 2.334 2.253 3.449 1.324 4.085 1.556.636.232-.453-.756-.847-1.2-.72-.81-2.426-1.196-2.426-1.196s1.385-.153 1.656-1.172c.25-.944-.016-1.467-.713-1.834z"
      fill="#fff"
    />
    <path
      d="M36.434 42.656v-.619l.481.344.962 3.642-1.443-3.367z"
      fill="#fff"
    />
    <path
      d="M75.433 48.73l6.073 2.642.284 1.463-2.91 4.268-.344-2.594 2.387-2.584-2.672 1.705-2.818-4.9zM85.474 46.854l-3.702 4.466.284 1.463 5.716 2.534-1.088-2.754-4.177-1.015 3.682.158-.715-4.852z"
      fill="#F7E9E3"
    />
    <path
      d="M52.93 70.385s1.1 14.956 9.353 21.003c7.757 5.683 17.813 3.892 26.977 6.822 17.443 5.578 28.055 37.52 28.055 37.52M72.15 31.343s19.896-2.457 26.783 5.505c4.647 5.374 4.789 12.464 7.909 18.825 8.497 17.328 40.263 29.126 40.263 29.126M115.393 92.642s10.778-1.225 14.571 3.208c2.749 3.212 2.457 8.047 4.225 11.89 4.603 10.008 17.721 14.536 17.721 14.536"
      stroke="#fff"
      strokeWidth={0.65}
      strokeDasharray="3.25 3.25"
    />
  </svg>
);

export default SvgIllustrationFlying;
